import { ensureNonNull } from "@utils/assertion";
import { type VerifaiNavigationProps } from "@newgenerated/shared/schema";
import { serialize_VerifaiNavigationProps, deserialize_VerifaiNavigationProps } from "@newgenerated/shared/mapper";

export { type VerifaiNavigationProps } from "@newgenerated/shared/schema";
export const parseVerifaiNavigationProps = (data: unknown): VerifaiNavigationProps => {
  return ensureNonNull(deserialize_VerifaiNavigationProps(JSON.stringify(data)));
};
export const renderVerifaiNavigationProps = (data: VerifaiNavigationProps): any => {
  return JSON.parse(serialize_VerifaiNavigationProps(data));
};
