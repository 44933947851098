import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { GaButton } from "@/components/general/GaButton";
import type { Language } from "@utils/type/type";
import type { GaVueComponent } from "@/common/vueUtils";
import type { Store } from "@/common/storeUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

function getSupportLink(language: Language): string {
  switch (language) {
    case "en":
      return `https://support.getabstract.com/en/knowledge/getabstract-ai`;
    case "de":
      return `https://support.getabstract.com/de/knowledge/wie-benutzt-man-das-getabstract-ki-tool`;
    case "fr":
      return `https://support.getabstract.com/fr/knowledge/comment-utiliser-loutil-ia-de-getabstract`;
    case "es":
      return `https://support.getabstract.com/es/knowledge/c%C3%B3mo-utilizar-la-herramienta-de-inteligencia-artificial-de-getabstract`;
    default:
      return `https://support.getabstract.com/en/knowledge/getabstract-ai`;
  }
}

export function InitialForm(props: { inputStore: Store<string>; submitInitialQuestion: (question: string) => void; language: Language; showAiDataProtectionDisclaimer: boolean }): GaVueComponent {
  const { t } = useGaContext();

  function submitForm(e: Event): void {
    e.preventDefault();
    props.submitInitialQuestion(props.inputStore.get());
  }

  return (
    <div class="d-grid gap-4">
      <h1 class="m-0">{t("search:askGa.productTitle")}</h1>
      <p class="getabstract-ai__description" vHtml={t("search:askGa.description.v2", [getSupportLink(props.language)])} />
      <form action="" class="getabstract-ai__form" onSubmit={submitForm}>
        <GaFormFieldInputText name="question" aria-labelledby="askGetabstractBtn" placeholder={t("search:askGa.askInputPlaceholder")} value={props.inputStore.get()} onUpdateValue={props.inputStore.set} />
        <GaButton type="submit" id="askGetabstractBtn">
          {t("search:askGa.askButtonLabel")}
        </GaButton>
      </form>
      {props.showAiDataProtectionDisclaimer ? (
        <p class="getabstract-ai__explanation small">
          <i class="ico-info-circle"></i>
          <span>{t("search:askGa.aiDataProtectionDisclaimer")}</span>
        </p>
      ) : null}
    </div>
  );
}
