import type { SummaryMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaButton } from "@/components/general/GaButton";
import { Language } from "@utils/type/type";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { addToast } from "@/components/general/GaToast";
import { useCustomModal } from "@/components/general/GaModal";
import { bookmarkMiab } from "@generated/api/mIABControllerApi";
import type { Store } from "@/common/storeUtils";
import { trackOutboundLinkClick, trackShareEvent } from "@generated/api/analyticTrackingControllerApi";
import { getPageViewMeta } from "@/components/analytic/_outbound-link-tracking";
import Arc from "./arc.svg?url";
import FancyShapes from "./fancyShapes.svg?url";
import MobileShapes from "./evenFancierShapes.svg?url";
import SpeechBubble from "./speechBubble.svg?url";
import { GaBadge } from "@/components/general/GaBadge";
import { SummaryCardMini } from "@/components/cards/SummaryCardMini";
import { getShareLinks } from "@/common/shareUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

async function copyToClipboard(miabLink: string, successMessage: string, dataId: number): Promise<void> {
  await navigator.clipboard.writeText(miabLink);
  addToast({
    message: successMessage,
  });
  await trackShareEvent({ id: BigInt(dataId), contentItemType: "MIAB", shareMedium: "COPY_LINK" });
}

async function openPopup(summary: SummaryMetadata, t: TranslationFn): Promise<void> {
  const miabLink = new URL(window.location.origin + "/" + Language.getCurrentLanguage() + "/miab/" + summary.dataId);
  await useCustomModal()({
    body: () => (
      <div class="miab-shareModal">
        <div class="miab-shareModal__description">
          <h3>{t("miab:page.shareModal.title")}</h3>
          <p>{t("miab:page.shareModal.description")}</p>
        </div>
        <div class="miab-shareModal__summary">
          <SummaryCardMini summary={summary} link={miabLink} />
        </div>
        <div class="miab-shareModal__shareLink">
          <p class="caption">{t("miab:page.shareModal.linkCaption")}</p>
          <GaFormFieldInputText name="miabShareLink" value={miabLink.href} onUpdateValue={() => void 0} disabled={true} />
          <div class="miab-shareModal__shareLinkButton">
            <GaButton onClick={() => copyToClipboard(miabLink.href, t("miab:page.shareModal.copySuccessful") ?? "", summary.dataId)}>{t("miab:page.shareModal.copyLink")}</GaButton>
          </div>
        </div>
      </div>
    ),
    size: "lg",
  });
}

async function bookmark(dataId: number, bookmarkStore: Store<boolean>): Promise<void> {
  bookmarkStore.set(await bookmarkMiab(dataId));
}

async function trackScheduleEventLink(dataId: number, url: string, targetName: string): Promise<void> {
  const meta = getPageViewMeta();
  if (meta !== null) {
    await trackOutboundLinkClick({ id: dataId, targetUrl: url, targetName: targetName, type: "MIAB_SCHEDULE", meta: meta });
  }
}

export function Header(props: { summary: SummaryMetadata; bookmarkStore: Store<boolean>; userPortal: string | null }): GaVueComponent {
  const { t, currentLanguage } = useGaContext();
  const miabLink = window.location.origin + "/" + currentLanguage() + "/miab/" + props.summary.dataId + (props.userPortal !== null ? "?u=" + props.userPortal : "");
  const description = t("miab:addToCalendar.description", [props.summary.title ?? "", miabLink]) ?? "";
  const title = t("miab:addToCalendar.title", [props.summary.title ?? ""]) ?? "";
  const links = getShareLinks(title, description);
  return (
    <>
      <div class="miab-header__image">
        <img src={props.summary.coverUri} class="img-fluid miab-header__image-img" alt={props.summary.title + " cover"} />
      </div>
      <div class="miab-header__title">
        <div class="mb-1 miab-badge--light">
          <GaBadge variant="orange-light">{t("miab:page.header.badge")?.toUpperCase()}</GaBadge>
        </div>
        <h1 class="miab-header__title-h1">{props.summary.title}</h1>
      </div>
      <div class="d-flex gap-3 flex-wrap miab-header__action">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
            <i class="ico-calendar" />
            &nbsp;{t("miab:page.actions.scheduleGroupDiscussion")}
          </button>
          <ul class="dropdown-menu">
            {links.map((shareLink) => (
              <li>
                <a class="dropdown-item" href={shareLink.href} onClick={() => trackScheduleEventLink(props.summary.dataId, shareLink.href, shareLink.title)} target="_blank">
                  {shareLink.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <GaButton onClick={() => openPopup(props.summary, t)}>
          <i class="ico-share-alt2" />
          &nbsp;{t("miab:page.actions.share")}
        </GaButton>
        <GaButton onClick={() => bookmark(props.summary.dataId, props.bookmarkStore)}>
          {props.bookmarkStore.get() ? <i class="ico-bookmark-fill" /> : <i class="ico-bookmark" />}
          &nbsp;{props.bookmarkStore.get() ? t("miab:page.actions.saved") : t("miab:page.actions.save")}
        </GaButton>
        <GaButton variant="primary-alt" target="_blank" href={`/miab/${props.summary.dataId}/pdf`}>
          <i class="ico-download" />
          &nbsp;{t("miab:page.actions.pdf")}
        </GaButton>
      </div>
      <div class="miab-header__background">
        <img src={Arc} alt="getAbstract Arc" class="miab-header__arc" />
        <img src={FancyShapes} alt="Some fancy shapes for visual experience" class="miab-header__shapes" />
        <img src={MobileShapes} alt="Some fancy shapes for mobile" class="miab-header__shapesMobile" />
        <img src={SpeechBubble} alt="Speech bubble" class="miab-header_speechBubble" />
      </div>
    </>
  );
}
