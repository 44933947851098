import type { DeepReadonly } from "vue";
import type { FormFilter, FormFilterName, FullSearchProps, FullSearchResults, SearchFiltersForm, SummarySearchFilters } from "@newgenerated/shared/schema";
import { assert, assertIsDefined } from "@utils/assertion";
import { simpleBackgroundWorker } from "@/components/ask-getabstract/utils/backgroundWorker";
import { delay } from "@utils/asyncUtils";
import { startTimer } from "@/components/ask-getabstract/utils/animationUtils";
import { STREAMING_SPEED_UP_FACTOR, STREAMING_TOKEN_PER_SEC, WAITING_INTERVAL_IN_MS } from "@/components/ask-getabstract/utils/store";
import { type ContentTypeDiscriminator, type ContentTypeProps, defaultAmount, type FormFilterExtended, type SearchProps, type SearchStoreState } from "@/components/search/fullSearchStoreTypes";
import type { MultiSelectStateProps } from "@/components/form/GaFormFieldMultiSelect";
import { createStore, type Store } from "@/common/storeUtils";
import { hasFeature } from "@/common/featureUtils";
import { createQuestion, getQuestionAnswers } from "@generated/api/askGetabstractControllerApi";
import { useI18n } from "@/i18n/i18nSetup";
import { Language } from "@utils/type/type";

export function getFilter(formFilters: DeepReadonly<FormFilterExtended[]>, filterType: FormFilterName): DeepReadonly<FormFilterExtended | null> {
  return formFilters.find((filter) => filter.identifier === filterType) ?? null;
}

function convertToNumbers(input: string[]): number[] {
  return input.map((value) => {
    const number = parseInt(value);
    if (Number.isNaN(number)) {
      throw Error("could not parse number");
    }
    return number;
  });
}

function convertToLanguages(values: string[]): Language[] {
  return values.map((value) => {
    assert(Language.getValues().includes(value as Language));
    return value as Language;
  });
}

export function convertToSummarySearchFilters(summaryFormFilters: DeepReadonly<FormFilterExtended[]>): SummarySearchFilters {
  const audioFilter = getActiveFilterValues(summaryFormFilters, "AUDIO").at(0);
  const miabFilter = getActiveFilterValues(summaryFormFilters, "MIAB").at(0);
  assert(audioFilter === "true" || audioFilter === "false" || audioFilter === undefined);
  assert(miabFilter === "true" || miabFilter === "false" || miabFilter === undefined);
  return {
    qualityFormFilter: [...getActiveFilterValues(summaryFormFilters, "QUALITY")],
    ratingFormFilter: convertToNumbers([...getActiveFilterValues(summaryFormFilters, "RATING")]),
    sourceFormFilter: [...getActiveFilterValues(summaryFormFilters, "SOURCE")],
    languageFormFilter: [...getActiveFilterValues(summaryFormFilters, "LANGUAGE")],
    audioFormFilter: audioFilter === "true",
    publicationDateFormFilter: convertToNumbers([...getActiveFilterValues(summaryFormFilters, "PUBLICATION_DATE")]),
    miabFormFilter: miabFilter === "true",
  };
}

function convertToSearchFiltersForm(summaryFormFilters: DeepReadonly<FormFilterExtended[]>, actionableFormFilters: DeepReadonly<FormFilterExtended[]>, searchTerm: string, summariesPage: number, channelPage: number): SearchFiltersForm {
  return {
    summarySearchFilters: convertToSummarySearchFilters(summaryFormFilters),
    actionableLanguageFormFilter: convertToLanguages([...getActiveFilterValues(actionableFormFilters, "LANGUAGE")]),
    query: searchTerm,
    summariesPage: summariesPage,
    channelPage: channelPage,
  };
}

function getContentType(contentTypes: DeepReadonly<ContentTypeProps[]>, discriminator: ContentTypeDiscriminator): DeepReadonly<ContentTypeProps> {
  const contentType = contentTypes.find((c) => c.kind === discriminator);
  assertIsDefined(contentType);
  return contentType;
}

type SearchFunction = (searchFiltersForm: SearchFiltersForm) => Promise<FullSearchResults>;

export async function executeSearch(searchProps: Store<SearchProps>, contentTypes: Store<ContentTypeProps[]>, newSearchTerm: string, searchFunction: SearchFunction, softReload: boolean = false): Promise<void> {
  searchProps.sub("status").set(softReload ? "LOADMORE" : "LOADING");
  searchProps.sub("searchTerm").set(newSearchTerm);
  try {
    const summary = getContentType(contentTypes.get(), "SUMMARY");
    const actionable = getContentType(contentTypes.get(), "ACTIONABLE");
    const channel = getContentType(contentTypes.get(), "CHANNEL");
    const result = await searchFunction(convertToSearchFiltersForm(summary.filterProps.formFilters, actionable.filterProps.formFilters, searchProps.sub("searchTerm").get(), summary.paging.page, channel.paging.page));
    const convertedContentTypes = contentTypes.get().map((cat): DeepReadonly<ContentTypeProps> => {
      switch (cat.kind) {
        case "CHANNEL":
          return { ...cat, items: result.channels, paging: result.channelPaging };
        case "SUMMARY":
          return {
            ...cat,
            items: result.summaries,
            filterProps: { formFilters: result.summaryFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })), formFilterExtended: cat.filterProps.formFilterExtended },
            paging: result.summariesPaging,
          };
        case "CUSTOMPAGE":
          return { ...cat, items: result.customPages };
        case "ACTIONABLE":
          return {
            ...cat,
            items: result.actionables,
            filterProps: { formFilters: result.actionableFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })), formFilterExtended: cat.filterProps.formFilterExtended },
          };
      }
    });
    applyQueryParamsToUrl(convertedContentTypes);

    contentTypes.set(convertedContentTypes);
    searchProps.sub("status").set("IDLE");
    searchProps.set({ ...searchProps.get(), params: new URLSearchParams(result.downloadSourceParams.additionalProperties) });
  } catch (_) {
    searchProps.sub("status").set("ERROR");
  }
}

export function getActiveFilterValues(formFilters: DeepReadonly<FormFilterExtended[]>, filterType: FormFilterName): readonly string[] {
  const filter = getFilter(formFilters, filterType);
  return filter !== null ? filter.activeValues : [];
}

export function applyQueryParamsToUrl(contentTypeProps: DeepReadonly<ContentTypeProps[]>): void {
  const queryParams = new URLSearchParams(window.location.search);
  contentTypeProps.forEach((contentType) =>
    contentType.filterProps.formFilters.forEach((filter) => {
      const parameterName = (contentType.kind !== "SUMMARY" ? contentType.kind.toLowerCase() : "") + filter.name;
      queryParams.delete(parameterName);
      filter.activeValues.forEach((value) => {
        if (!(filter.identifier === "AUDIO" && filter.activeValues.at(0) === "false") && !(filter.identifier === "MIAB" && filter.activeValues.at(0) === "false")) {
          queryParams.append(parameterName, value);
        }
      });
    }),
  );
  history.replaceState(null, "", "?" + queryParams.toString());
}

export function readQueryParametersFromUrl(formFilters: FormFilterExtended[], contentType: ContentTypeDiscriminator): FormFilterExtended[] {
  const queryParams = new URLSearchParams(window.location.search);
  return formFilters.map((filter) => {
    const filterName = contentType.toLowerCase() + filter.name;
    const values = queryParams.getAll(filterName);
    return {
      ...filter,
      activeValues: values.length > 0 ? values : filter.activeValues,
    };
  });
}

export function getMultiSelectProps(formFilter: FormFilter): MultiSelectStateProps<string> | null {
  if (formFilter.type === "MULTISELECT") {
    return {
      searchTerm: "",
      options: formFilter.options,
      showSearch: false,
    };
  }
  return null;
}

export function createInitialSearchStore(props: FullSearchProps): Store<SearchStoreState> {
  const { t } = useI18n();
  const convertedSummaryFormFilters = props.initialSummaryFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) }));
  const store = createStore<SearchStoreState>({
    contentTypeProps: [
      {
        kind: "SUMMARY",
        title: t("general:summaries"),
        amountToShow: defaultAmount["SUMMARY"],
        items: [],
        filterProps: { formFilters: readQueryParametersFromUrl(convertedSummaryFormFilters, "SUMMARY"), formFilterExtended: false },
        paging: {
          page: 0,
          totalCount: BigInt(0),
        },
      },
      {
        kind: "ACTIONABLE",
        title: t("general:actionables"),
        amountToShow: defaultAmount["ACTIONABLE"],
        items: [],
        filterProps: {
          formFilters: readQueryParametersFromUrl(
            props.initialActionableFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })),
            "ACTIONABLE",
          ),
          formFilterExtended: false,
        },
        paging: { page: 0, totalCount: BigInt(0) },
      },
      { kind: "CUSTOMPAGE", title: t("customPage:customPages"), amountToShow: defaultAmount["CUSTOMPAGE"], items: [], filterProps: { formFilters: [], formFilterExtended: false }, paging: { page: 0, totalCount: BigInt(0) } },
      {
        kind: "CHANNEL",
        title: props.beta ? t("general:skills") : t("general:channels"),
        amountToShow: defaultAmount["CHANNEL"],
        items: [],
        filterProps: { formFilters: [], formFilterExtended: false },
        paging: { page: 0, totalCount: BigInt(0) },
      },
    ],
    searchProps: {
      params: new URLSearchParams(),
      status: "LOADING",
      selectedContentType: "ALL",
      searchTerm: "",
    },
    aiState: {
      kind: "NOT_AVAILABLE",
    },
  });

  void hasFeature("GET_ABSTRACT_AI").then((featureAvailable) => {
    if (featureAvailable) {
      store.sub("aiState").set({
        kind: "AVAILABLE",
        displayFullAnswer: false,
        uiState: { kind: "INITIAL" },
      });

      void simpleBackgroundWorker(
        {
          streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
          streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
          analyticsEventVariant: "qa_triggered_from_search",
        },
        {
          getInteractions: () => store.sub("searchProps").get(),
          updateUi: (newState) => {
            const aiState = store.sub("aiState").unpackUnion();
            assert(aiState.kind === "AVAILABLE");
            aiState.store.sub("uiState").set(newState);
          },
          initQuestionAnswer: createQuestion,
          getQuestionAnswers: getQuestionAnswers,
          delay: () => delay(WAITING_INTERVAL_IN_MS),
          isAborted: () => false,
          startTimer,
        },
      );
    }
  });
  return store;
}
