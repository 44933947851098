<template>
  <div :class="cardClasses" :data-ga-data-id="content.contentId.id" :lang="content.common.language">
    <div class="card-cover">
      <a :href="cardLink" :target="target" tabindex="-1" v-if="cardLink">
        <Cover :content="content" />
      </a>
      <Cover :content="content" v-else />
    </div>
    <CardBody :content="content" :link="cardLink" />
    <CardActions :content="content" :ix-status="ixStatus" :allow-delete="allowDelete" :allow-bookmark="allowDelete" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ContentMetadata, ContentMetadataCommon, SummaryIxStatusView } from "@newgenerated/shared/schema";
import Cover from "@utils/vue-migration/component/cards/Cover.vue";
import CardBody from "@utils/vue-migration/component/cards/CardBody.vue";
import CardActions from "@utils/vue-migration/component/cards/CardActions.vue";

type Layout = "vertical" | "horizontal";

function getLink(common: ContentMetadataCommon, customLink?: string, additionalParams?: string): string | null {
  const link = customLink ?? common.link;
  if (additionalParams !== undefined) {
    return link != null && link.includes("?") ? link + "&" + additionalParams : link + "?" + additionalParams;
  }
  return link;
}

export default defineComponent({
  components: { CardActions, CardBody, Cover },
  props: {
    content: {
      type: Object as PropType<ContentMetadata>,
      required: true,
    },
    layout: {
      type: String as PropType<Layout>,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
    additionalParams: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      required: false,
    },
    allowBookmark: {
      type: Boolean,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      required: false,
    },
    ixStatus: {
      type: Object as PropType<SummaryIxStatusView>,
      required: false,
    },
  },
  setup: (props) => {
    const cardLink = getLink(props.content.common, props.link, props.additionalParams);
    const cardClasses = ["content-card", "content-card--" + props.layout, props.content.common.inactive ? "content-card--inactive" : "", cardLink === null || cardLink.trim() === "" ? "content-card--no-link" : ""];
    return {
      cardLink,
      cardClasses,
    };
  },
});
</script>
