<template>
  <div class="card-body">
    <CardBadges :content="content" />
    <a v-if="link !== null && content.common.leadIn !== null" :href="link" :target="target" tabindex="-1">
      <p class="card-author small" :title="content.common.leadIn">{{ content.common.leadIn }}</p>
    </a>
    <p v-else-if="content.common.leadIn !== null" class="card-author small" :title="content.common.leadIn">{{ content.common.leadIn }}</p>
    <a v-if="link !== null && content.common.title !== ''" class="focus-ring d-block" :href="link" :target="target">
      <h4 class="card-title" :title="content.common.title">{{ content.common.title }}</h4>
    </a>
    <h4 v-else-if="content.common.title !== ''" class="card-title" :title="content.common.title">{{ content.common.title }}</h4>
    <a v-if="link !== null && content.common.description !== null" :href="link" :target="target" tabindex="-1">
      <p class="card-text small" :title="content.common.description">{{ content.common.description }}</p>
    </a>
    <p v-else-if="content.common.description !== null" class="card-text small" :title="content.common.description">{{ content.common.description }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ContentMetadata } from "@newgenerated/shared/schema";
import CardBadges from "@utils/vue-migration/component/cards/CardBadges.vue";

export default defineComponent({
  components: { CardBadges },
  props: {
    content: {
      type: Object as PropType<ContentMetadata>,
      required: true,
    },
    link: {
      type: String as PropType<string | null>,
      required: true,
    },
    target: {
      type: String,
      required: false,
    },
  },
  setup: () => {},
});
</script>
