import { ensureNonNull } from "@utils/assertion";
import { type MIABPopularSummariesResult } from "@newgenerated/shared/schema";
import { serialize_MIABPopularSummariesResult, deserialize_MIABPopularSummariesResult } from "@newgenerated/shared/mapper";

export { type MIABPopularSummariesResult } from "@newgenerated/shared/schema";
export const parseMIABPopularSummariesResult = (data: unknown): MIABPopularSummariesResult => {
  return ensureNonNull(deserialize_MIABPopularSummariesResult(JSON.stringify(data)));
};
export const renderMIABPopularSummariesResult = (data: MIABPopularSummariesResult): any => {
  return JSON.parse(serialize_MIABPopularSummariesResult(data));
};
