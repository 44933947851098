/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type VerifaiContentStatus, parseVerifaiContentStatus, renderVerifaiContentStatus } from './verifaiContentStatus';
// @ts-ignore
import { type VerifaiFormatType, parseVerifaiFormatType, renderVerifaiFormatType } from './verifaiFormatType';
// @ts-ignore
import { type VerifaiRating, parseVerifaiRating, renderVerifaiRating } from './verifaiRating';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type VerifaiOverviewItem = {
    verifaiContentId: number;
    title: string;
    resourceUrl: string;
    hashId: string;
    uploader: string;
    verifier: string;
    editor: string;
    publisher: string;
    formatType: VerifaiFormatType;
    rating: VerifaiRating;
    contentStatus: VerifaiContentStatus;
    invoiced: boolean;
}

export const parseVerifaiOverviewItem = (data: any): VerifaiOverviewItem => {
    return {
        verifaiContentId: ensureNonNull<number>(data.verifaiContentId, "verifaiContentId is required"),
        title: ensureNonNull<string>(data.title, "title is required"),
        resourceUrl: ensureNonNull<string>(data.resourceUrl, "resourceUrl is required"),
        hashId: ensureNonNull<string>(data.hashId, "hashId is required"),
        uploader: ensureNonNull<string>(data.uploader, "uploader is required"),
        verifier: ensureNonNull<string>(data.verifier, "verifier is required"),
        editor: ensureNonNull<string>(data.editor, "editor is required"),
        publisher: ensureNonNull<string>(data.publisher, "publisher is required"),
        formatType: parseVerifaiFormatType(ensureNonNull<VerifaiFormatType>(data.formatType, "formatType is required")),
        rating: parseVerifaiRating(ensureNonNull<VerifaiRating>(data.rating, "rating is required")),
        contentStatus: parseVerifaiContentStatus(ensureNonNull<VerifaiContentStatus>(data.contentStatus, "contentStatus is required")),
        invoiced: ensureNonNull<boolean>(data.invoiced, "invoiced is required"),
    }
}

export const renderVerifaiOverviewItem = (data: VerifaiOverviewItem): any => {
    return {
        verifaiContentId: data.verifaiContentId,
        title: data.title,
        resourceUrl: data.resourceUrl,
        hashId: data.hashId,
        uploader: data.uploader,
        verifier: data.verifier,
        editor: data.editor,
        publisher: data.publisher,
        formatType: renderVerifaiFormatType(data.formatType),
        rating: renderVerifaiRating(data.rating),
        contentStatus: renderVerifaiContentStatus(data.contentStatus),
        invoiced: data.invoiced,
    }
}


