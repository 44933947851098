// @ts-ignore
import { gaApiCall } from "@utils/httpClient";
// @ts-ignore
import { hasProp, assert } from "@utils/assertion";
// @ts-ignore
import { renderJsonWithNumbersAsBigint, parseJsonWithNumbersAsBigint } from "@utils/json";
import * as schema from "@newgenerated/experimental/schema";
import * as mapper from "@newgenerated/experimental/mapper";
export async function testExplodedQueryParameter(props: { experimentModelQuery: schema.ExperimentModel }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/exploded";
  const urlSearchParams = new URLSearchParams();
  (() => {
    const outerParsed = ((value) => {
      const outerRendered = ((value) => {
        return mapper.serialize_ExperimentModel(value);
      })(value);
      const outerParsed = parseJsonWithNumbersAsBigint(outerRendered);
      assert(outerParsed != null);
      assert(typeof outerParsed == "object");
      return outerParsed;
    })(props.experimentModelQuery);
    assert(hasProp(outerParsed, "booleanExample"));
    urlSearchParams.append(
      "booleanExample",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.booleanExample);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "booleanNullableExample"));
    if (outerParsed.booleanNullableExample != null) {
      urlSearchParams.append(
        "booleanNullableExample",
        ((value) => {
          const parsed_again = parseJsonWithNumbersAsBigint(value);
          assert(typeof parsed_again === "boolean");
          return parsed_again ? "true" : "false";
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.booleanNullableExample);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "doubleExample"));
    urlSearchParams.append(
      "doubleExample",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.doubleExample);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "doubleNullableExample"));
    if (outerParsed.doubleNullableExample != null) {
      urlSearchParams.append(
        "doubleNullableExample",
        ((value) => {
          return value;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.doubleNullableExample);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "floatExample"));
    urlSearchParams.append(
      "floatExample",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.floatExample);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "floatNullableExample"));
    if (outerParsed.floatNullableExample != null) {
      urlSearchParams.append(
        "floatNullableExample",
        ((value) => {
          return value;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.floatNullableExample);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "intExample"));
    urlSearchParams.append(
      "intExample",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.intExample);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "intNullableExample"));
    if (outerParsed.intNullableExample != null) {
      urlSearchParams.append(
        "intNullableExample",
        ((value) => {
          return value;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.intNullableExample);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "longExample"));
    urlSearchParams.append(
      "longExample",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.longExample);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "longNullableExample"));
    if (outerParsed.longNullableExample != null) {
      urlSearchParams.append(
        "longNullableExample",
        ((value) => {
          return value;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.longNullableExample);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "stringExample"));
    urlSearchParams.append(
      "stringExample",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.stringExample);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "stringNullableExample"));
    if (outerParsed.stringNullableExample != null) {
      urlSearchParams.append(
        "stringNullableExample",
        ((value) => {
          const parsed_again = parseJsonWithNumbersAsBigint(value);
          assert(typeof parsed_again === "string");
          return parsed_again;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.stringNullableExample);
          })(),
        ),
      );
    }
  })();
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentModel } => {
    const parsed = mapper.deserialize_ExperimentModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getExperimentalModel(props: { experimentId: number; experimentQuery: number }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.experimentId),
  );
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append(
    "experimentQuery",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.experimentQuery),
  );
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentModel } => {
    const parsed = mapper.deserialize_ExperimentModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function updateExperimentModel(props: { body: schema.ExperimentModel; experimentId: number }): Promise<{ kind: "success"; data: schema.ExperimentModel } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.experimentId),
  );
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_ExperimentModel(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentModel } => {
    const parsed = mapper.deserialize_ExperimentModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function createExperimentModel(props: { body: schema.ExperimentModel; experimentId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/experiment/{experimentId}";
  path = path.replace(
    "{experimentId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.experimentId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_ExperimentModel(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getExperimentalDemoModel(): Promise<{ kind: "success"; data: schema.ExperimentalDemoModel } | { kind: "error"; message: string }> {
  const path = "/gaapi/experimental/demo";
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentalDemoModel } => {
    const parsed = mapper.deserialize_ExperimentalDemoModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function putExperimentalDemoModel(props: { body: schema.ExperimentalDemoModel }): Promise<{ kind: "success"; data: schema.ExperimentalDemoModel } | { kind: "error"; message: string }> {
  const path = "/gaapi/experimental/demo";
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_ExperimentalDemoModel(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ExperimentalDemoModel } => {
    const parsed = mapper.deserialize_ExperimentalDemoModel(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}
