<template>
  <button :class="computedClasses">
    <slot></slot>
  </button>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

type Variant = "danger";
export default defineComponent({
  props: {
    variant: {
      type: String as PropType<Variant>,
      required: false,
    },
  },
  setup: (props) => {
    const computedClasses = ["btn", "action-button", props.variant === "danger" ? "action-button--danger" : ""];
    return {
      computedClasses,
    };
  },
});
</script>
