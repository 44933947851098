<template>
  <span :class="computedClasses">
    <i v-if="typeof icon !== 'undefined'" :class="icon" aria-hidden="true"></i>
    <slot></slot>
  </span>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import "./_gaBadgeCustom.scss";

type Variant = "primary" | "primary-dark" | "success" | "danger" | "warning" | "muted" | "primary-light" | "secondary-light" | "tertiary-light" | "purple-light" | "orange-light" | "neutral-warm-light" | "gradient-light";

export default defineComponent({
  inheritAttrs: false,
  props: {
    variant: {
      type: String as PropType<Variant>,
      default: "primary",
    },
    icon: {
      type: String,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedClasses = computed((): string[] => {
      const classes = ["badge", `badge-${props.variant}`];
      if (props.pill) {
        classes.push("rounded-pill");
      }
      if (props.caption) {
        classes.push("badge-caption");
      }
      return classes;
    });
    return { computedClasses };
  },
});
</script>
