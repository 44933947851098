import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { assertIsDefined } from "@utils/assertion";
import { GaTooltip } from "@/components/general/GaTooltip";
import { GaButton } from "@/components/general/GaButton";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export const ReferenceSnippet = defineComponent({
  props: {
    snippet: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useGaContext();
    const snippetText = ref<HTMLElement | null>(null);
    const isEllipsis = ref<boolean>(false);

    const handleResize = (): void => {
      assertIsDefined(snippetText.value);
      isEllipsis.value = snippetText.value.scrollHeight > snippetText.value.clientHeight;
    };

    onMounted(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return () => (
      <div class="summary-card-referenced__snippet">
        <span class="summary-card-referenced__snippet-text" ref={snippetText}>
          {props.snippet}
        </span>
        {isEllipsis.value ? (
          <GaTooltip title={`<div class="text-start">${props.snippet}</div>`} htmlEnabled={true} placement="bottom" class="summary-card-referenced__read-more">
            <GaButton variant="link-slim">{t("summary:referenceCard.seeMore")}</GaButton>
          </GaTooltip>
        ) : null}
      </div>
    );
  },
});
