import type { GaVueComponent } from "@/common/vueUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function NoResults(): GaVueComponent {
  const { t } = useGaContext();
  return (
    <div class="my-4">
      <h3>{t("miab:page.noResults.title")}</h3>
      <p>{t("miab:page.noResults.description")}</p>
    </div>
  );
}
