import type { GaVueComponent } from "@/common/vueUtils";
import { GaButton } from "@/components/general/GaButton";
import type { DeepReadonly } from "vue";
import { Interactions, type QuestionInteraction } from "@/components/ask-getabstract/utils/store";
import type { Store } from "@/common/storeUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function RelatedQuestions(props: { questionStore: Store<QuestionInteraction>; relatedQuestions: DeepReadonly<string[]> }): GaVueComponent | null {
  const { t } = useGaContext();
  if (props.relatedQuestions.length === 0) {
    return null;
  }

  return (
    <div class="d-grid gap-3">
      <h4 class="m-0">{t("search:askGa.followUpQuestions.title")}</h4>
      <div class="getabstract-ai__related-questions">
        {props.relatedQuestions.map((question) => (
          <GaButton key={question} variant="link-slim" onClick={() => Interactions.chooseRelatedQuestion(props.questionStore, question)}>
            <span class="getabstract-ai__related-question">{question}</span>
          </GaButton>
        ))}
      </div>
    </div>
  );
}
