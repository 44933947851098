<template>
  <div v-if="content.contentType === 'SUMMARY'" class="card-cover-background--summary" :style="content.backgroundColor !== null ? '--card-cover-bg-color: ' + content.backgroundColor + ';' : ''">
    <object class="card-cover-img" :data="content.common.coverUri + '?s=S'" type="image/jpeg">
      <!-- Fallback image: -->
      <!-- TODO: Replace with neutral image or color -->
      <img class="card-cover-img" :src="'/summary-img/32513.jpg?s=S'" alt="" />
    </object>
  </div>
  <div v-else-if="content.contentType === 'MIAB'" class="card-cover-background--miab">
    <img :src="'/www/images/miab/layer-1.svg'" class="card-cover-background--miab-layer" alt="" />
    <img class="card-cover-img" :src="content.common.coverUri" alt="" />
    <img :src="'/www/images/miab/layer-2.svg'" class="card-cover-background--miab-layer2" alt="" />
  </div>
  <object v-else class="card-cover-img" :data="content.common.coverUri + '?s=S'">
    <img class="card-cover-img" :src="'/actionable-img/151-the-first-actionable-test-1734363051000.jpg?s=S'" alt="" />
  </object>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ContentMetadata } from "@newgenerated/shared/schema";

export default defineComponent({
  props: {
    content: {
      type: Object as PropType<ContentMetadata>,
      required: true,
    },
  },
  setup: () => {},
});
</script>
