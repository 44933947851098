// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { type ReadonlyUint8Array } from "@utils/arrayUtils";
export type AdditionalPropertiesExample1 = {
  num: number;
  additionalProperties: Record<string, string>;
};

export type AdditionalPropertiesExample2 = {
  additionalProperties: Record<
    string,
    {
      foo?: number;
    }
  >;
};

export type AdditionalPropertiesExample3 = { additionalProperties: Record<string, number | null> };

export type AdditionalPropertiesExample4 = {
  subobj: { additionalProperties: Record<string, string> };
};

export type AllOfExample = {
  a: string;
  b: string;
  c: string;
  d?: string;
  e: string;
  f?: string;
};

export type AllOfExpandingRef = {
  a: string;
  b: string;
  c: string;
  d?: string;
  e: string;
  f?: string;
  x: string;
  y: string;
};

export type AllOfRefExample = {
  a: AllOfExample | null;
};

export type ArrayOfArray = {
  arr_of_arr: number[][];
};

export type BinaryData = {
  file: ReadonlyUint8Array;
};

export type BytesData = {
  data: ReadonlyUint8Array;
};

export type DateObj = {
  dt?: { year: number; month: number; day: number } | null;
};

export type DateTimeObj = {
  dt?: DateTime<true> | null;
};

export type EmptyObjectExample = {
  schema?: any;
};

export namespace ExampleEnum {
  const values = ["FOO", "BAR"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ExampleEnum = ExampleEnum.Impl;

export type ExperimentModel = {
  booleanExample: boolean;
  booleanNullableExample: boolean | null;
  doubleExample: number;
  doubleNullableExample: number | null;
  floatExample: number;
  floatNullableExample: number | null;
  intExample: number;
  intNullableExample: number | null;
  longExample: bigint;
  longNullableExample: bigint | null;
  stringExample: string;
  stringNullableExample: string | null;
};

export type ExperimentOptionalModel = {
  intExample: number;
  intNullableExample: number | null;
  intOptionalExample?: number;
  intOptionalNullableExample?: number | null;
};

export type ExperimentRefModel = {
  nullableSubModel: SubModel | null;
  subComponent: {
    intValue: number;
  };
  subModel: SubModel;
};

export type ExperimentalDemoModel = {
  text: string;
};

export type ExternalTypeExample = {
  typed?: ("IBAN" | "ABA" | "SWIFTBIC" | "CHEQUE") | null;
  untyped?: string | null;
};

export type NamedEnumInArr = {
  arr?: ExampleEnum[];
};

export type NamedEnumInObj = {
  value?: ExampleEnum;
};

export type NestedBytesData = {
  inner: BytesData;
};

export type NonRequiredNullable = {
  data?: number | null;
};

export type NullableObjectInObject = {
  inner1: {
    inner2: string;
  } | null;
};

export type ObjInArrInArr = {
  arr1: {
    arr2: {
      obj1: {
        arr3: any[];
      };
    }[];
  }[];
};

export type ObjInObjInObj = {
  obj1: {
    obj2: {
      obj3: {
        str: string;
      };
    };
  };
};

export type ObjectArrayContainer = {
  arr1: {
    intValue: number;
  }[];
  arr2:
    | {
        intValue: number;
      }[]
    | null;
  arr3?: {
    intValue: number;
  }[];
  arr4?:
    | {
        intValue: number;
      }[]
    | null;
};

export type OneOfEarlyName = { a_kind: "num"; elem: string };

export type OneOfExplicitDiscriminatorExample = { kind: "num"; num?: number } | { kind: "string"; str?: string };

export type OneOfExplicitDiscriminatorMultiNestingExample =
  | {
      kind: "obj";
      obj_data: {
        inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string };
      };
    }
  | { kind: "string"; str_data: string };

export type OneOfInsideArrayExample = {
  arr: ({ kind: "one"; one_data: number } | { kind: "two"; two_data: string })[];
};

export type OneOfLateName = { z_kind: "num"; elem: string };

export type OneOfPartAExample = {
  kind: "A";
  maybeString: string;
  sharedProp: string;
  subNonNullable: SuperPartAExample;
  subNullable: SuperPartAExample;
};

export type OneOfPartBExample = {
  kind: "B";
  sharedProp: string;
  subNonNullable: SuperPartBExample;
  subNullable: SuperPartBExample;
};

export type OneOfPartCExample = {
  kind: "C";
  maybeString: string;
  sharedProp: string;
  subNonNullable: SuperExample;
};

export type OneOfPartsBaseExample =
  | { kind: "A"; maybeString: string; sharedProp: string; subNonNullable: SuperPartAExample; subNullable: SuperPartAExample }
  | { kind: "B"; sharedProp: string; subNonNullable: SuperPartBExample; subNullable: SuperPartBExample }
  | { kind: "C"; maybeString: string; sharedProp: string; subNonNullable: SuperExample };

export type OneOfUsePartExample = {
  bbb: OneOfPartBExample;
};

export type OneOfWithOverlappingProperties = { kind: "str"; value: string } | { kind: "num"; value: number };

export type OneOfWithRef = { kind: "elem"; value: string };

export type OneOfWithRefElem = {
  kind: OneOfWithRefElemKind;
  value: string;
};

export namespace OneOfWithRefElemKind {
  const values = ["elem"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type OneOfWithRefElemKind = OneOfWithRefElemKind.Impl;

export type RefArrayContainer = {
  arr1: SubModel[];
  arr2: SubModel[] | null;
  arr3?: SubModel[];
  arr4?: SubModel[] | null;
};

export type ReservedKeywords = {
  object?: string;
  val?: string;
  var?: string;
};

export type StringArrayContainer = {
  arr1: string[];
  arr2: string[] | null;
  arr3: (string | null)[];
  arr4: (string | null)[] | null;
  arr5?: string[];
  arr6?: string[] | null;
  arr7?: (string | null)[];
  arr8?: (string | null)[] | null;
};

export type SubModel = {
  intValue: number;
};

export type SuperExample = { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean };

export type SuperPartAExample = {
  kind: "AAA";
  value: string;
};

export type SuperPartBExample = {
  kind: "BBB";
  value: boolean;
};

export type TimeStampExample = {
  ts: DateTime<true>;
};

export type UnNamedEnumInArr = {
  arr?: (("FOO" | "BAR") | null)[] | null;
};

export type UnnamedEnumInObj = {
  enumvalue?: ("FOO" | "BAR") | null;
};

export type UriExample = {
  url: string;
};

export type UrlExample = {
  url: string;
};
