import type { ContentCardDemoProps } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { GaButton } from "@/components/general/GaButton";
import ContentCard from "@utils/vue-migration/component/cards/ContentCard.vue";
import type { PropType } from "vue";
import { defineComponent } from "vue";

function ensureNotNull<T>(item: T | undefined): T {
  if (item === undefined) {
    throw new Error("Item is undefined");
  }
  return item;
}

function Demo(props: { contentCardDemoProps: ContentCardDemoProps; title: string; layout: "horizontal" | "vertical" }): GaVueComponent {
  return (
    <div>
      <h2>{props.title}</h2>
      <div data-card-demo="">
        <div class="demo-bar mb-3">
          <span>
            <GaBadge variant="muted">VUE</GaBadge>
          </span>
          <div class="demo-bar-controls">
            <GaButton size={"sm"} data-demo-control="add-column">
              +
            </GaButton>
            <GaButton size={"sm"} data-demo-control="remove-column">
              -
            </GaButton>
          </div>
        </div>
        <div class="demo-flexible-columns" style={"--row-number: ".concat(props.layout === "horizontal" ? "2;" : "3;")}>
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[4])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[4])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[4])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[4])} layout={props.layout} />
        </div>
      </div>
    </div>
  );
}

export const ContentCardDemo = defineComponent({
  props: {
    contentCardDemoProps: {
      type: Object as PropType<ContentCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    return () => (
      <div>
        <Demo contentCardDemoProps={props.contentCardDemoProps} title={"Vertical"} layout={"vertical"} />
        <Demo contentCardDemoProps={props.contentCardDemoProps} title={"Horizontal"} layout={"horizontal"} />
      </div>
    );
  },
});
