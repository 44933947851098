<template>
  <div>
    <slot name="fallback" v-if="state.kind === 'fallback'"></slot>
    <slot name="loaded" :data="state.data" v-if="state.kind === 'loaded'"></slot>
    <slot name="error" v-if="state.kind === 'error'"></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, type PropType, ref } from "vue";

type LoadingState = {
  kind: "fallback";
};

type LoadedState = {
  kind: "loaded";
  data: unknown;
};

type ErrorState = {
  kind: "error";
};

type State = LoadingState | LoadedState | ErrorState;

export default defineComponent({
  props: {
    fetch: {
      type: Function as PropType<() => Promise<unknown>>,
      required: true,
    },
  },
  setup: (props) => {
    const state = ref<State>({
      kind: "fallback",
    });
    onMounted(async () => {
      try {
        const result = await props.fetch();
        state.value = { kind: "loaded", data: result };
      } catch (_) {
        state.value = { kind: "error" };
      }
    });

    return {
      state,
    };
  },
});
</script>
