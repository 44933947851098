import { defineComponent, nextTick, type PropType } from "vue";
import type { ContentMetadata, MIABPageProps, MIABQuestionMetadata, SummaryMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaNumberedList } from "@/components/general/GaNumberedList";
import "./pageComponents/_miab.scss";
import type { Store } from "@/common/storeUtils";
import { createStore } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import { GaSpinner } from "@/components/general/GaSpinner";
import { Header } from "@/components/miab/pageComponents/Header";
import { Feedback } from "@/components/miab/pageComponents/Feedback";
import { Language } from "@utils/type/type";
import { GaBadge } from "@/components/general/GaBadge";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import ContentCard from "@utils/vue-migration/component/cards/ContentCard.vue";

type SummaryStatus = {
  show: boolean;
  loading: boolean;
};

type MIABPageState = {
  summaryStatus: SummaryStatus;
  bookmarked: boolean;
};

function openSummary(store: Store<SummaryStatus>): void {
  store.set({ show: true, loading: true });
  // eslint-disable-next-line no-restricted-syntax
  void nextTick(() => {
    scrollTo({ top: 0 });
  });
}

function Prepare(props: { summaryMetadata: SummaryMetadata; summaryStore: Store<SummaryStatus>; keyInsights: string[] }): GaVueComponent {
  const { t } = useGaContext();
  return (
    <>
      <div class="mb-2">
        <GaBadge variant="orange-light">{t("miab:page.prepare.badge")?.toUpperCase()}</GaBadge>
      </div>
      <h2>{t("miab:page.prepare.title")}</h2>
      <p>
        <strong>{t("miab:page.prepare.description")}</strong>
      </p>
      <p>
        <span vHtml={t("miab:page.prepare.keyInsights", [props.summaryMetadata.title ?? ""])} />
      </p>
      <ul class="mb-4">
        {props.keyInsights.map((value) => (
          <li>{value}</li>
        ))}
      </ul>
      <p class="mb-0">
        <GaButton onClick={() => openSummary(props.summaryStore)}>{t("miab:page.prepare.openSummary")}</GaButton>
      </p>
    </>
  );
}

function Discuss(props: { questions: MIABQuestionMetadata[] }): GaVueComponent {
  const { t } = useGaContext();
  return (
    <>
      <div class="mb-2">
        <GaBadge variant="orange-light">{t("miab:page.discuss.badge")?.toUpperCase()}</GaBadge>
      </div>
      <h2>{t("miab:page.discuss.title")}</h2>
      <p>
        <strong>{t("miab:page.discuss.description")}</strong>
      </p>
      <div class="miab-questions">
        <GaNumberedList items={props.questions.map((question) => ({ title: question.title, text: question.question }))} />
      </div>
    </>
  );
}

function Implement(): GaVueComponent {
  const { t } = useGaContext();
  return (
    <div>
      <div class="mb-2">
        <GaBadge variant={"orange-light"}>{t("miab:page.implement.badge")?.toUpperCase()}</GaBadge>
      </div>
      <h2>{t("miab:page.implement.title")}</h2>
      <p>
        <b>{t("miab:page.implement.description")}</b>
      </p>
      <ul class="miab-implement__list">
        {t("miab:page.implement.bulletList")
          ?.split("<br>")
          .map((value) => <li>{value}</li>)}
      </ul>
    </div>
  );
}

function RelatedSummaries(props: { relatedSummaries: ContentMetadata[] }): GaVueComponent {
  const { t } = useGaContext();
  return (
    <>
      <h2 class="mb-4">{t("miab:page.relatedContent.title")}</h2>
      <div class="row">
        {props.relatedSummaries.map((summary) => (
          <div class="col-lg-6 col-12 mb-3">
            <ContentCard content={summary} layout="horizontal" />
          </div>
        ))}
      </div>
    </>
  );
}

export function Content(props: { pageProps: MIABPageProps; store: Store<MIABPageState> }): GaVueComponent {
  const link = `/${Language.getCurrentLanguage()}/summary/${props.pageProps.summary.dataId}?iframe=true&o_s=MIAB`;
  const { t } = useGaContext();
  return (
    <div class="d-flex justify-content-center">
      {!props.store.sub("summaryStatus").get().show ? (
        <div>
          <div class="mb-4 card no-shadow miab-header">
            <Header summary={props.pageProps.summary} bookmarkStore={props.store.sub("bookmarked")} userPortal={props.pageProps.userPortal} />
          </div>
          <div class="mb-4 card no-shadow miab-section">
            <Prepare summaryMetadata={props.pageProps.summary} keyInsights={props.pageProps.keyInsights} summaryStore={props.store.sub("summaryStatus")} />
          </div>
          <div class="mb-4 card no-shadow miab-section">
            <Discuss questions={props.pageProps.questions} />
          </div>
          <div class="mb-4 card no-shadow miab-section">
            <Implement />
          </div>
          <div class="miab-spacing">
            <Feedback dataId={props.pageProps.summary.dataId} summaryTitle={props.pageProps.summary.title ?? ""} feedback={props.pageProps.feedback} />
          </div>
          {props.pageProps.similarMiabs.length > 0 ? (
            <div class="mb-4 miab-section">
              <RelatedSummaries relatedSummaries={props.pageProps.similarMiabs} />
            </div>
          ) : (
            <div class="mb-5"></div>
          )}
        </div>
      ) : (
        <div class="miab-summary">
          <div class="card miab-summary__card">
            <div class="miab-summary__title-section">
              <h3 class="miab-summary__title-section-title">{t("miab:page.summaryIframe.title")}</h3>
              <GaButton onClick={() => props.store.sub("summaryStatus").sub("show").set(false)}>
                <i class="ico-close" />
                &nbsp;{t("general:button.close")}
              </GaButton>
            </div>
            {props.store.sub("summaryStatus").get().loading ? (
              <div class="d-flex justify-content-center mt-5">
                <GaSpinner />
              </div>
            ) : null}
            <iframe class={["miab-summary__iframe", props.store.sub("summaryStatus").get().loading ? "d-none" : ""]} src={link} width="100%" onLoad={() => props.store.sub("summaryStatus").sub("loading").set(false)} />
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * Meeting in a box page
 */
export const MIABPage = defineComponent({
  props: {
    miabPageProps: {
      type: Object as PropType<MIABPageProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createStore<MIABPageState>({
      summaryStatus: {
        show: false,
        loading: false,
      },
      bookmarked: props.miabPageProps.bookmarked,
    });
    return () => (
      <div class="miab">
        <Content pageProps={props.miabPageProps} store={store} />
      </div>
    );
  },
});
