<template>
  <div class="card-badges">
    <Loading :fetch="() => loadTranslation(content.common.language)">
      <template #loading>
        <div class="placeholder-wave">
          <div class="placeholder" style="height: 1.3125rem; width: 5rem"></div>
        </div>
      </template>
      <template #loaded="{ data }">
        <GaBadge v-if="content.common.inactive" :caption="true" variant="muted">
          {{
            //@ts-ignore: gaDesign complain because data is of type unknown. Is allowed in gaVue, but forbidden in gaDesign.
            data("browse:icon.inprogress")
          }}
        </GaBadge>
        <GaBadge :caption="true" v-else-if="content.contentType === 'SUMMARY'" variant="primary-light"
          >{{
            //@ts-ignore: gaDesign complain because data is of type unknown. Is allowed in gaVue, but forbidden in gaDesign.
            data("summary:source." + content.sourceType)
          }}
        </GaBadge>
        <GaBadge v-else-if="content.contentType === 'ACTIONABLE'" :caption="true" variant="purple-light">
          {{
            //@ts-ignore: gaDesign complain because data is of type unknown. Is allowed in gaVue, but forbidden in gaDesign.
            data("actionable:source.actionable")
          }}
        </GaBadge>
        <GaBadge v-else-if="content.contentType === 'SKETCHNOTE'" variant="tertiary-light" :caption="true"
          >{{
            //@ts-ignore: gaDesign complain because data is of type unknown. Is allowed in gaVue, but forbidden in gaDesign.
            data("lgxp:source.sketchnotes")
          }}
        </GaBadge>
        <GaBadge v-else-if="content.contentType === 'MIAB'" variant="orange-light" :caption="true"
          >{{
            //@ts-ignore: gaDesign complain because data is of type unknown. Is allowed in gaVue, but forbidden in gaDesign.
            data("miab:source.miab")
          }}
        </GaBadge>
        <GaBadge v-if="content.contentType === 'SUMMARY' && content.common.audioActive" variant="primary-light" icon="ico-earphones" :caption="true"></GaBadge>
      </template>
    </Loading>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ContentMetadata } from "@newgenerated/shared/schema";
import GaBadge from "@utils/vue-migration/component/general/GaBadge.vue";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import GaSuspense from "@utils/vue-migration/component/general/GaSuspense.vue";

export default defineComponent({
  components: { Loading: GaSuspense, GaBadge },
  props: {
    content: {
      type: Object as PropType<ContentMetadata>,
      required: true,
    },
  },
  setup: () => {
    const { t, loadTranslation } = useGaContext();
    return { t, loadTranslation };
  },
});
</script>
