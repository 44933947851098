import type { GaVueComponent } from "@/common/vueUtils";
import { Interactions } from "@/components/ask-getabstract/utils/store";
import type { Store } from "@/common/storeUtils";
import type { Dict } from "@utils/dictUtils";
import { GaFeedback } from "@/components/general/GaFeedback";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function Feedback(props: { feedbackStore: Store<Dict<boolean>>; question: string; questionUuid: string; showMailFeedbackLink?: boolean; border?: boolean }): GaVueComponent {
  const { t } = useGaContext();

  function createGiveFeedbackHandler() {
    return (isPositive: boolean) => Interactions.giveFeedback(props.feedbackStore, props.questionUuid, isPositive);
  }

  return (
    <>
      <GaFeedback
        title={t("search:askGa.feedback.title", "")}
        changeHandler={createGiveFeedbackHandler()}
        showMailFeedbackLink={props.showMailFeedbackLink}
        mailTitle={`[uuid:${props.questionUuid}]: I want to give Beta feedback`}
        mailBody={`I asked the question: [question:${props.question}].`}
        buttonBorder={props.border}
        positiveFeedbackText={t("search:askGa.feedback.helpful", "")}
        negativeFeedbackText={t("search:askGa.feedback.notHelpful", "")}
      />
    </>
  );
}
