import { ensureNonNull } from "@utils/assertion";
import { type ContentCardDemoProps } from "@newgenerated/shared/schema";
import { serialize_ContentCardDemoProps, deserialize_ContentCardDemoProps } from "@newgenerated/shared/mapper";

export { type ContentCardDemoProps } from "@newgenerated/shared/schema";
export const parseContentCardDemoProps = (data: unknown): ContentCardDemoProps => {
  return ensureNonNull(deserialize_ContentCardDemoProps(JSON.stringify(data)));
};
export const renderContentCardDemoProps = (data: ContentCardDemoProps): any => {
  return JSON.parse(serialize_ContentCardDemoProps(data));
};
