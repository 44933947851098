import { DateTime } from "luxon";

const DATETIME_ISO = "yyyy-MM-dd'T'HH:mm:ss";

export type ShareLink = {
  href: string;
  title: string;
};

function googlifyDate(value: string): string {
  return encodeURIComponent(value.replace(/-/g, "").replace(/:/g, ""));
}

function teamsifyDate(value: DateTime): string {
  return encodeURIComponent(value.toFormat("MM/dd/yyyy HH:mm:ss"));
}

export function getShareLinks(dateTitle: string, dateDescription: string): ShareLink[] {
  const currentDate = DateTime.now().set({ second: 0 });
  const dateFrom = currentDate.plus({ minutes: 30 - (currentDate.minute % 30) });
  const dateTo = dateFrom.plus({ hours: 1 });
  const currentDateFormatted = dateFrom.toFormat(DATETIME_ISO);
  const dateToFormatted = dateTo.toFormat(DATETIME_ISO);
  const title = encodeURIComponent(dateTitle);
  const description = encodeURIComponent(dateDescription);
  const googleLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${googlifyDate(currentDateFormatted)}/${googlifyDate(dateToFormatted)}&details=${description}&location=&trp=false`;
  const outlookLink = `https://outlook.office.com/calendar/0/action/compose?allday=false&body=${description}&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${encodeURIComponent(currentDateFormatted)}&enddt=${encodeURIComponent(dateToFormatted)}&subject=${title}`;
  const teamsLink = `https://teams.microsoft.com/l/meeting/new?subject=${title}&content=${description}&startTime=${teamsifyDate(dateFrom)}&endTime=${teamsifyDate(dateTo)}`;
  return [
    { href: outlookLink, title: "Outlook" },
    { href: googleLink, title: "Google" },
    { href: teamsLink, title: "Teams" },
  ];
}
