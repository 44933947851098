import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { GaFeedback } from "@/components/general/GaFeedback";
import { giveFeedback } from "@generated/api/mIABControllerApi";

async function updateFeedback(isPositive: boolean, dataId: number): Promise<void> {
  await giveFeedback({ dataId, isPositive });
}

export function Feedback(props: { dataId: number; summaryTitle: string; feedback: boolean | null }): GaVueComponent {
  const { t } = useI18n();
  return (
    <GaFeedback
      title={t("miab:page.feedback.title")}
      positiveFeedbackText={t("miab:page.feedback.positive")}
      negativeFeedbackText={t("miab:page.feedback.negative")}
      changeHandler={(value) => updateFeedback(value, props.dataId)}
      showMailFeedbackLink={true}
      mailBody={t("miab:page.feedback.mailBody", [props.summaryTitle])}
      mailTitle={t("miab:page.feedback.mailTitle", [props.summaryTitle])}
      value={props.feedback ?? undefined}
    />
  );
}
