import type { GaVueComponent } from "@/common/vueUtils";
import type { VerifaiNavLink } from "@generated/model/verifaiNavLink";
import type { VerifaiNavigationProps } from "@generated/model/verifaiNavigationProps";
import VerifaiLogoSvg from "./resources/verifai-logo-nav.svg?url";
import "@/components/verifai/verifai-navigation.scss";

function ItemList(props: { navigationItems: VerifaiNavLink[] }): GaVueComponent {
  return (
    <ul class="verifai-navigation__list--first-level" role="list">
      {props.navigationItems.map((navigationItem) => {
        return (
          <li class="verifai-navigation__item--first-level">
            <a href={navigationItem.url} class="verifai-navigation__item-link">
              <span class="verifai-navigation__item-icon">{navigationItem.ico !== null ? <i class={navigationItem.ico}> </i> : null}</span>
              <strong>{navigationItem.name}</strong>
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export function VerifaiNavigation(props: { verifaiNavigationProps: VerifaiNavigationProps }): GaVueComponent {
  return (
    <>
      <div class="verifai-navigation__mobile-header">
        <button class="btn btn-lg btn-link" data-bs-toggle="offcanvas" data-bs-target="#verifai-content" aria-controls="verifai-content">
          <i class="ico-menu-hamburger" aria-hidden="true"></i>
        </button>
        <img src={VerifaiLogoSvg} class="verifai-navigation__mobile-header-logo" alt="Verify" />
      </div>
      <div class="d-flex verifai-sidebar">
        <div class="offcanvas-lg offcanvas-start d-flex verifai-content__wrapper" tabindex="-1" id="verifai-content" aria-labelledby="verifai-content-label">
          <div class="d-lg-none">
            <button type="button" class="btn btn-lg btn-link" data-bs-dismiss="offcanvas" data-bs-target="#verifai-content" aria-label="Close">
              <i class="ico-menu-hamburger" aria-hidden="true"></i>
            </button>
            <h4 class="offcanvas-title visually-hidden" id="verifai-content-label">
              Navigation
            </h4>
          </div>
          <div class="offcanvas-body d-flex flex-column flex-grow-1">
            <nav class="verifai-navigation" aria-labelledby="verifai-navigation-title" id="verifai-navigation__accordion">
              <h3 id="verifai-navigation-title" class="verifai-navigation__title">
                <img src={VerifaiLogoSvg} alt="Verify Navigation" />
              </h3>
              {props.verifaiNavigationProps.navLinks.length === 0 ? (
                <>
                  <p class="m-0">
                    <em>No navigation item found.</em>
                  </p>
                </>
              ) : (
                <ItemList navigationItems={props.verifaiNavigationProps.navLinks} />
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
