// @ts-ignore
import { registerVueWidgetForJsp } from "@/Registry";
// @ts-ignore
import { defineComponent, type PropType } from "@/common/vueUtils";
// @ts-ignore
import { ActionablePage } from "@/components/actionable/actionable-page/ActionablePage";
// @ts-ignore
import { FullSearch } from "@/components/search/FullSearch";
// @ts-ignore
import { AskGetabstractWidget } from "@/components/ask-getabstract/AskGetabstract";
// @ts-ignore
import { AskGetabstractIframe } from "@/components/ask-getabstract/AskGetabstractIframe";
// @ts-ignore
import { AskGetabstractDemo } from "@/components/ask-getabstract/demo/AskGetabstractDemo";
// @ts-ignore
import { VerifaiCreatorPage } from "@/components/verifai/VerifaiCreatorPage";
// @ts-ignore
import { VerifaiNavigation } from "@/components/verifai/VerifaiNavigation";
// @ts-ignore
import { GaTopLayer } from "@/components/general/GaTopLayer";
// @ts-ignore
import { WarmupV2 } from "@/components/warmup/WarmupV2";
// @ts-ignore
import { WarmupV2Demo } from "@/components/warmup/demo/WarmupV2Demo";
// @ts-ignore
import { ModalDemo } from "@/components/general/demo/ModalDemo";
// @ts-ignore
import { ExperimentalGeneratorDemo } from "@/components/demo/ExperimentalGeneratorDemo";
// @ts-ignore
import { GaProgressCircleDemo } from "@/components/general/demo/GaProgressCircleDemo";
// @ts-ignore
import { SummaryCardDefaultDemo } from "@/components/cards/SummaryCardDemo";
// @ts-ignore
import { SummaryCardCompactDemo } from "@/components/cards/SummaryCardDemo";
// @ts-ignore
import { SummaryCardMiniDemo } from "@/components/cards/SummaryCardDemo";
// @ts-ignore
import { FullSearchAiDemoComponent } from "@/components/search/FullSearchAiDemoComponent";
// @ts-ignore
import { MIABPage } from "@/components/miab/MIABPage";
// @ts-ignore
import { BadgeDemo } from "@/components/general/demo/BadgeDemo";
// @ts-ignore
import { GaFeedbackDemo } from "@/components/general/demo/GaFeedbackDemo";
// @ts-ignore
import { MIABOverviewPage } from "@/components/miab/MIABOverviewPage";
// @ts-ignore
import { ContentCardDemo } from "@/components/general/demo/ContentCardDemo";
// @ts-ignore
import type { ActionablePageProps, FullSearchProps, AskGetabstractProperties, VerifaiCreatorPageProps, VerifaiNavigationProps, WarmupV2WidgetProps, SummaryCardDemoProps, MIABPageProps, MIABOverviewPageProps, ContentCardDemoProps } from "@newgenerated/shared/schema";
// @ts-ignore
import { parseActionablePageProps } from "@generated/model/actionablePageProps";
// @ts-ignore
import { parseFullSearchProps } from "@generated/model/fullSearchProps";
// @ts-ignore
import { parseAskGetabstractProperties } from "@generated/model/askGetabstractProperties";
// @ts-ignore
import { parseVerifaiCreatorPageProps } from "@generated/model/verifaiCreatorPageProps";
// @ts-ignore
import { parseVerifaiNavigationProps } from "@generated/model/verifaiNavigationProps";
// @ts-ignore
import { parseWarmupV2WidgetProps } from "@generated/model/warmupV2WidgetProps";
// @ts-ignore
import { parseSummaryCardDemoProps } from "@generated/model/summaryCardDemoProps";
// @ts-ignore
import { parseMIABPageProps } from "@generated/model/mIABPageProps";
// @ts-ignore
import { parseMIABOverviewPageProps } from "@generated/model/mIABOverviewPageProps";
// @ts-ignore
import { parseContentCardDemoProps } from "@generated/model/contentCardDemoProps";

const ActionablePageWidget = defineComponent({
   props: {
       actionablePageProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseActionablePageProps(props.actionablePageProps);
       // @ts-ignore
       return () => <ActionablePage actionablePageProps={parsed} />;
   },
});
registerVueWidgetForJsp("ActionablePage", ActionablePageWidget);

const FullSearchWidget = defineComponent({
   props: {
       fullSearchProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseFullSearchProps(props.fullSearchProps);
       // @ts-ignore
       return () => <FullSearch fullSearchProps={parsed} />;
   },
});
registerVueWidgetForJsp("FullSearch", FullSearchWidget);

const AskGetabstractWidgetWidget = defineComponent({
   props: {
       askGetabstractProperties: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseAskGetabstractProperties(props.askGetabstractProperties);
       // @ts-ignore
       return () => <AskGetabstractWidget askGetabstractProperties={parsed} />;
   },
});
registerVueWidgetForJsp("AskGetabstractWidget", AskGetabstractWidgetWidget);

const AskGetabstractIframeWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <AskGetabstractIframe />;
   },
});
registerVueWidgetForJsp("AskGetabstractIframe", AskGetabstractIframeWidget);

const AskGetabstractDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <AskGetabstractDemo />;
   },
});
registerVueWidgetForJsp("AskGetabstractDemo", AskGetabstractDemoWidget);

const VerifaiCreatorPageWidget = defineComponent({
   props: {
       verifaiCreatorPageProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseVerifaiCreatorPageProps(props.verifaiCreatorPageProps);
       // @ts-ignore
       return () => <VerifaiCreatorPage verifaiCreatorPageProps={parsed} />;
   },
});
registerVueWidgetForJsp("VerifaiCreatorPage", VerifaiCreatorPageWidget);

const VerifaiNavigationWidget = defineComponent({
   props: {
       verifaiNavigationProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseVerifaiNavigationProps(props.verifaiNavigationProps);
       // @ts-ignore
       return () => <VerifaiNavigation verifaiNavigationProps={parsed} />;
   },
});
registerVueWidgetForJsp("VerifaiNavigation", VerifaiNavigationWidget);

const GaTopLayerWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <GaTopLayer />;
   },
});
registerVueWidgetForJsp("GaTopLayer", GaTopLayerWidget);

const WarmupV2Widget = defineComponent({
   props: {
       warmupV2WidgetProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseWarmupV2WidgetProps(props.warmupV2WidgetProps);
       // @ts-ignore
       return () => <WarmupV2 warmupV2WidgetProps={parsed} />;
   },
});
registerVueWidgetForJsp("WarmupV2", WarmupV2Widget);

const WarmupV2DemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <WarmupV2Demo />;
   },
});
registerVueWidgetForJsp("WarmupV2Demo", WarmupV2DemoWidget);

const ModalDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <ModalDemo />;
   },
});
registerVueWidgetForJsp("ModalDemo", ModalDemoWidget);

const ExperimentalGeneratorDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <ExperimentalGeneratorDemo />;
   },
});
registerVueWidgetForJsp("ExperimentalGeneratorDemo", ExperimentalGeneratorDemoWidget);

const GaProgressCircleDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <GaProgressCircleDemo />;
   },
});
registerVueWidgetForJsp("GaProgressCircleDemo", GaProgressCircleDemoWidget);

const SummaryCardDefaultDemoWidget = defineComponent({
   props: {
       summaryCardDemoProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseSummaryCardDemoProps(props.summaryCardDemoProps);
       // @ts-ignore
       return () => <SummaryCardDefaultDemo summaryCardDemoProps={parsed} />;
   },
});
registerVueWidgetForJsp("SummaryCardDefaultDemo", SummaryCardDefaultDemoWidget);

const SummaryCardCompactDemoWidget = defineComponent({
   props: {
       summaryCardDemoProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseSummaryCardDemoProps(props.summaryCardDemoProps);
       // @ts-ignore
       return () => <SummaryCardCompactDemo summaryCardDemoProps={parsed} />;
   },
});
registerVueWidgetForJsp("SummaryCardCompactDemo", SummaryCardCompactDemoWidget);

const SummaryCardMiniDemoWidget = defineComponent({
   props: {
       summaryCardDemoProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseSummaryCardDemoProps(props.summaryCardDemoProps);
       // @ts-ignore
       return () => <SummaryCardMiniDemo summaryCardDemoProps={parsed} />;
   },
});
registerVueWidgetForJsp("SummaryCardMiniDemo", SummaryCardMiniDemoWidget);

const FullSearchAiDemoComponentWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <FullSearchAiDemoComponent />;
   },
});
registerVueWidgetForJsp("FullSearchAiDemoComponent", FullSearchAiDemoComponentWidget);

const MIABPageWidget = defineComponent({
   props: {
       miabPageProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseMIABPageProps(props.miabPageProps);
       // @ts-ignore
       return () => <MIABPage miabPageProps={parsed} />;
   },
});
registerVueWidgetForJsp("MIABPage", MIABPageWidget);

const BadgeDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <BadgeDemo />;
   },
});
registerVueWidgetForJsp("BadgeDemo", BadgeDemoWidget);

const GaFeedbackDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <GaFeedbackDemo />;
   },
});
registerVueWidgetForJsp("GaFeedbackDemo", GaFeedbackDemoWidget);

const MIABOverviewPageWidget = defineComponent({
   props: {
       miabOverviewPageProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseMIABOverviewPageProps(props.miabOverviewPageProps);
       // @ts-ignore
       return () => <MIABOverviewPage miabOverviewPageProps={parsed} />;
   },
});
registerVueWidgetForJsp("MIABOverviewPage", MIABOverviewPageWidget);

const ContentCardDemoWidget = defineComponent({
   props: {
       contentCardDemoProps: {
           type: Object as PropType<unknown>,
           required: true,
       },
   },
   setup(props) {
       const parsed = parseContentCardDemoProps(props.contentCardDemoProps);
       // @ts-ignore
       return () => <ContentCardDemo contentCardDemoProps={parsed} />;
   },
});
registerVueWidgetForJsp("ContentCardDemo", ContentCardDemoWidget);

